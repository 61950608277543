import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { homePageCarousels } from "../../api/api";
import { useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import "./homePage.scss";
import temp from "../../assets/test.jpeg";
const Carousels = () => {
  const navigate = useNavigate();
  const [banner, setBanner] = useState([]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    const loadData = () => {
      homePageCarousels().then((res) => {
        setBanner(res?.data);
      });
    };
    loadData();
  }, []);

  return (
    <div className="homeCrousel">
      <Carousel
        responsive={responsive}
        arrows={true}
        swipeable={true}
        draggable={true}
        autoPlay={false}
        showDots={false}
        ssr={true}
        renderDotsOutside={true}
        infinite
        containerClass="carousel-container"
      >
        {banner?.map((value) => {
          return (
            <div>
              <img
                src={value.image}
                className="bannerImage"
                onClick={() => window.open(value.url, "_blank")}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Carousels;
