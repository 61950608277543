import React from "react";
import "./ProfileDetails.scss";

const ProfileDetails = ({ state }) => {
  return (
    <>
      <div className="ProfileDetails">
        <div className="ProfileContainer">
          <div className="profileLeft">
            <div className="ProfileContent">
              <h1 className="ProfileHead">
                About{" "}
                <span
                  style={{
                    color: "black",
                  }}
                >
                  {state?.name}
                </span>
              </h1>
            </div>

            <div className="ProfileContent">
              <h4 className="ProfileHead">Biography</h4>
              <span className="ProfileDescriptions">
                {state?.bio === "undefined" ? "" : state?.bio}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDetails;
