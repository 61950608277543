import React from "react";
import Static from "../../components/StaicPage/Static";
const StaticsPages = () => {
  return (
    <div>
      <Static />
    </div>
  );
};

export default StaticsPages;
