import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { allBooks } from "../../api/api";
import { Helmet } from "react-helmet";
import SEO from "../../SEO";
export const Booklist = (props) => {
  let navigate = useNavigate();
  let location = useLocation();

  const [allBooksData, setAllBooksData] = React.useState([]);
  const [pageMetadata] = useState({
    pageTitle: "Kavishala Books | Book Lists",
    pageDescription:
      "Kavishala Books is not just a publishing house; it's a home for those who breathe words and stories. Founded by a group of passionate writers and literature enthusiasts, it was established with a singular mission: to nurture the literary talent of emerging authors and to provide a platform for their voices to be heard. Kavishala Books believes that every story deserves to be told, and every writer deserves a chance to shine.",
    pageImageUrl: "https://books.kavishala.com/images/post_og.png",
    pageUrl: "https://books.kavishala.com/BookList",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const loadData = () => {
      allBooks().then((res) => {
        setAllBooksData(res?.data);
      });
    };
    loadData();
  }, []);

  return (
    <>
      <SEO
        title={pageMetadata?.pageTitle}
        og_url={pageMetadata?.pageUrl}
        og_type="article"
        og_title={pageMetadata?.pageTitle}
        og_description={pageMetadata?.pageDescription}
        og_image={pageMetadata?.pageImageUrl}
      />
      <section className="BookList_MainWrapper">
        <div className="BookList_wrp">
          <div className="container">
            {" "}
            <div className="Category_Grid_Wrp">
              <>
                <div className="category_Grid_Content">
                  {/* {bookList?.map((ele, index) => ( */}
                  {allBooksData?.map((ele, index) => {
                    return (
                      <div key={index} className="Grid-item">
                        <figure>
                          <Link
                            to={`/BookList/${ele?.slug}`}
                            key={ele?.slug}
                            // onClick={() => goToBookDetailsPage(ele)}
                          >
                            <img src={ele?.thumbnail_image} alt="book" />
                          </Link>
                        </figure>
                        <figcaption>
                          <h3>{ele.book_name}</h3>
                          <p>Written by {ele?.author_name}</p>
                        </figcaption>
                      </div>
                    );
                  })}
                </div>
              </>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
