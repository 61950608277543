import React from "react";
import HomePage from "./pages/homePage/homePage.component";
import WebRoutes from "./pages/RouteSeperator/webRoutes.component";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import BooksDetail from "./pages/BooksDetail/BooksDetail.component";
import { Booklist } from "./pages/BookListing/Booklist";
import { BookDescription } from "./pages/BookDescription/BookDescription";
import { Author } from "./pages/author/Author";
import { AuhorDescription } from "./pages/author/authorDescription/AuhorDescription";
import Footer from "./components/Footer/Footer";
import StaticsPages from "./pages/Statics/StaticsPages";
import Published from "./pages/Publish/Published";
import Login from "./pages/Login/Login";
import Profile from "./pages/ProfileDetails/Profile";
export default function RouteSeperater() {
  const ProtectedComponent2 = ({ children }) => {
    if (!localStorage?.getItem("book_token")) {
      return <Navigate to="/Login" />;
    }
    return <>{children}</>;
  };
  return (
    <>
      <Router>
        <Routes>
          <Route element={<WebRoutes />}>
            <Route path="/" exact element={<HomePage />} />
            {/* <Route path="/" element={<Navigate to="/BookList" />} /> */}
            <Route path="/home" element={<HomePage />} />
            <Route path="/BookDetail" element={<BooksDetail />} />
            <Route path="/BookList" element={<Booklist />} />
            <Route path="/BookList/:slug" element={<BookDescription />} />
            <Route path="/Author" element={<Author />} />
            <Route path="/Author/:slug" element={<AuhorDescription />} />
            <Route path="/:slug" element={<StaticsPages />} />
            <Route path="/publish" element={<Published />} />
            <Route path="/login" element={<Login />} />

            <Route
              path="/Profile/:slug"
              element={
                <ProtectedComponent2>
                  <AuhorDescription />{" "}
                </ProtectedComponent2>
              }
            />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
//
