import React, { useState, useEffect } from "react";
import { getBookHistory } from "../../api/api";
import Classes from "../../components/appNavigation/_appNavigation.module.scss";
const BookHistory = () => {
  const [data, setData] = useState();
  const [data2, setData2] = useState();
  const linkarr = [
    {
      name: "Book Image",
      url: "/about-us",
    },
    {
      name: "Book Name",
      url: "/about-us",
    },
    {
      name: "Platform",
      url: "/about-us",
    },
    {
      name: "Book Count",
      url: "/Booklist",
    },
    {
      name: "Royality",
      url: "/Author",
    },
    {
      name: "Royality",
      url: "/Author",
    },
  ];

  useEffect(() => {
    const loadData = () => {
      getBookHistory()
        .then((res) => {
          setData(res?.data);
        })
        .catch((e) => console.log("error"));
    };
    loadData();
  }, []);
  var bookName;
  return (
    <section
      className="Main_HomeWrapper Description_wrapper BookDesciption_Wrapper AuthorDescription_Wrapper"
      id="Book History"
    >
      {localStorage?.getItem("book_token") ? (
        <div className="container">
          <div className="Otherbook_Wrapper Author_desc_Wrp">
            <div className="Otherbook-Heading">
              <h2>Book History</h2>
            </div>

            <div>
              <div>
                <div
                  className="book_platforms"
                  style={{ border: "1px solid grey" }}
                >
                  <p className="platformHead">Book Name</p>
                  <p className="platformHead">Platform</p>
                  <p className="platformHead">Book Count</p>
                  <p className="platformHead">Royality</p>
                </div>
              </div>

              {data?.map((ele, index) => {
                bookName = ele?.book?.book_name;
                return (
                  <div className="bookRow">
                    {ele?.platform?.map((value) => {
                      return (
                        <div className="book_platforms">
                          <p className="platform">{bookName}</p>
                          <p className="platform">{value?.platform}</p>

                          <p className="platform">{value?.book_count}</p>

                          <p className="platform">{value?.book_royalty}</p>
                        </div>
                      );
                    })}
                    <hr></hr>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* </div> */}
    </section>
  );
};

export default BookHistory;
