import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams } from "react-router-dom";
import { getBooksSlug } from "../../api/api";
import { allBooks } from "../../api/api";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import SEO from "../../SEO";
const AllBook = () => {
  const [book, setBook] = useState([]);
  const [allBooksData, setAllBooksData] = React.useState([]);
  const { slug } = useParams();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4.5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1220 },
      items: 4.5,
    },
    tablet: {
      breakpoint: { max: 1220, min: 480 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1.2,
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const loadData = () => {
      getBooksSlug(slug).then((res) => {
        setBook(res?.data?.book_images);
      });
    };
    loadData();
  }, [slug]);

  useEffect(() => {
    const loadData = () => {
      allBooks().then((res) => {
        setAllBooksData(res?.data);
      });
    };
    loadData();
  }, []);
  return (
    <>
      <section className="Main_HomeWrapper Description_wrapper BookDesciption_Wrapper AuthorDescription_Wrapper">
        <div className="container">
          <div className="authorNameContainer">
            <h1 className="authorName">All Books</h1>
          </div>
        </div>
      </section>
      <div className="allBookCaroselsContainer">
        <div className="allBookCarosels">
          <Carousel
            responsive={responsive}
            arrows={true}
            swipeable={true}
            draggable={true}
            autoPlay={false}
            showDots={false}
            ssr={true}
          >
            {allBooksData?.map((value) => {
              return (
                <Link to={`/BookList/${value?.slug}`} key={value?.slug}>
                  <Card>
                    <CardMedia
                      sx={{ width: 225, height: 325 }}
                      image={value?.thumbnail_image}
                      title="Book Image"
                    />
                    <div className="AudioTitleConatainer">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="AudioTitle"
                      >
                        {value?.name}
                      </Typography>
                    </div>
                  </Card>
                </Link>
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default AllBook;
