import React, { createContext, useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import temp from "../../assets/authBanner.png";
import "../../pages/BookDescription/BookDescription.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { getBooksSlug } from "../../api/api";
import { useParams } from "react-router-dom";
import AllBook from "./AllBook";
import { Helmet } from "react-helmet";
import SEO from "../../SEO";
import BookHistory from "./BookHistory";
const carouselProp = {
  showStatus: false,
  useKeyboardArrows: true,
  infiniteLoop: true,
  autoPlay: false,
  stopOnHover: true,
  emulateTouch: true,
  transitionTime: 400,
  showArrows: true,

  renderArrowPrev: (clickHandler, hasPrev, label) => {
    return (
      <span className="arrow-left" onClick={clickHandler}>
        <span className="icon-keyboard_arrow_left">
          <FontAwesomeIcon icon={faAngleLeft} color="red" />
        </span>
      </span>
    );
  },
  renderArrowNext: (clickHandler, hasNext, label) => {
    return (
      <span className="arrow-right" onClick={clickHandler}>
        <span className="icon-keyboard_arrow_right">
          <FontAwesomeIcon icon={faAngleRight} color="red" />
        </span>
      </span>
    );
  },
};

export const BookDescription = () => {
  const { slug } = useParams();
  const [book, setBook] = useState(() => {
    const storedBook = localStorage.getItem("book");
    return storedBook ? JSON.parse(storedBook) : null;
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchBookData = async () => {
      try {
        const response = await getBooksSlug(slug);
        const newBook = response.data;
        setBook(newBook);
        localStorage.setItem("book", JSON.stringify(newBook));
      } catch (error) {
        console.error("Error fetching book data:", error);
      }
    };

    fetchBookData();
  }, [slug]);
  useEffect(() => {
    if (book) {
      const { book_name, discription, slug, thumbnail_image } = book;
      const pageTitle = `Kavishala Books | Book Lists | ${book_name}`;
      document.title = pageTitle;
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", discription);
      }
    }
  }, [book]);

  return (
    <>
      {" "}
      {book && (
        <div>
          <SEO
            title={`Kavishala Books | Book Lists | ${book?.book_name}`}
            og_url={`/BookList/${book?.slug}`}
            og_type="article"
            og_title={`Kavishala Books | Book Lists | ${book?.book_name}`}
            og_description={book?.discription}
            og_image={book?.thumbnail_image}
          />

          <div className="bookDescriptionContainer">
            <div className="bookDescription">
              <div className="bookFirstContent">
                <h1 className="bookName">{book?.book_name}</h1>
                <h6 className="bookAuthor">Written by {book?.author?.name}</h6>
                <p
                  className="bookDiscription"
                  dangerouslySetInnerHTML={{ __html: book?.discription }}
                />
              </div>
              <div className="booksecondContent">
                <Carousel showArrows={true} {...carouselProp}>
                  {book?.book_images?.map((value) => {
                    return (
                      <div className="imageConatiner">
                        <img
                          className="caroselImage"
                          src={value?.book_images}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
          <section className="Main_HomeWrapper Description_wrapper BookDesciption_Wrapper AuthorDescription_Wrapper">
            <div className="container">
              <div className="authorNameContainer">
                <h1 className="authorName">About The Author</h1>
              </div>
              <div className="Description_Content">
                <div className="Description_Left">
                  <figure>
                    <div className="img_Wrp">
                      <img src={book?.author?.image} alt="Author profile" />
                    </div>
                  </figure>
                </div>
                <div className="Description_Right">
                  <div className="About_Book">
                    <div className="About-book-title">
                      <h2>{}</h2>
                    </div>
                    <figcaption>
                      <div className="bookDes_Wrp">
                        <div className="container">
                          <div className="bookDes_Wrp_Content">
                            {/* <h2>About The Author</h2> */}
                            <p>{book?.author?.about_author}</p>
                          </div>
                          {/* <div className="textLink">
                        {authorDetails?.author_links?.map((value) => {
                          return (
                            <span className="linkName">{value?.url_text}</span>
                          );
                        })}
                      </div> */}
                        </div>
                      </div>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AllBook book={book?.book_images} />
        </div>
      )}
    </>
  );
};
