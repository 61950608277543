import React, { Component } from "react";
import AppNavigation from "../../components/appNavigation/appNavigation.component";
import Carousels from "./Carousels";
import Video from "./Video";
import AllBook from "./AllBook";
import Publish from "./Publish";
import AllAuthors from "./AllAuthors";
import { Helmet } from "react-helmet";
import SEO from "../../SEO";
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Kavishala Books | Where Words Ignite",
      pageDescription:
        "Kavishala Books is not just a publishing house; it's a home for those who breathe words and stories. Founded by a group of passionate writers and literature enthusiasts, it was established with a singular mission: to nurture the literary talent of emerging authors and to provide a platform for their voices to be heard. Kavishala Books believes that every story deserves to be told, and every writer deserves a chance to shine.",
      pageImageUrl: "https://books.kavishala.com/images/post_og.png",
      pageUrl: "https://books.kavishala.com",
    };
  }

  render() {
    return (
      <div className="Main_Home_Content">
        <SEO
          title={this.state?.pageTitle}
          og_url={this.state?.pageUrl}
          og_type="article"
          og_title={this.state?.pageTitle}
          og_description={this.state?.pageDescription}
          og_image={this.state?.pageImageUrl}
        />
        <AppNavigation />
        <Carousels />
        <Video />
        <AllBook />
        <Publish />
        <AllAuthors />
      </div>
    );
  }
}

export default HomePage;
