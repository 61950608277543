import React, { useState, useEffect } from "react";

import { staticPage } from "../../api/api";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Static = () => {
  let { slug } = useParams();

  const [statices, setStatices] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const loadData = () => {
      staticPage(slug)
        .then((res) => {
          const response = res;

          setStatices(response.data);
        })
        .catch((e) => navigate(`/`));
    };
    loadData();
  }, [slug]);
  return <div dangerouslySetInnerHTML={{ __html: statices?.content }} />;
};

export default Static;
