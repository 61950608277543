import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import { allVideos } from "../../api/api";
import "./homePage.scss";
const Video = () => {
  const [data, setData] = useState([]);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    allVideos().then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <>
      <section className="Main_HomeWrapper Description_wrapper BookDesciption_Wrapper AuthorDescription_Wrapper">
        <div className="container2">
          <div className="authorNameContainer">
            <h1 className="authorName">All Videos</h1>
          </div>
        </div>
      </section>
      <div className="VideoCrousel">
        <Carousel
          responsive={responsive}
          arrows={true}
          swipeable={true}
          draggable={true}
          autoPlay={false}
          showDots={false}
          ssr={true}
          renderDotsOutside={true}
          infinite
          containerClass="carousel-container"
        >
          {data?.map((value) => {
            return (
              <ReactPlayer
                width="95%"
                controls={true}
                className="react-player-video"
                url={value?.video_link}
              />
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default Video;
