import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./SignUp/Signup.scss";
import { useNavigate } from "react-router-dom";
import { publish } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const PublishForm = () => {
  const [data, setData] = useState();
  const [errors, setError] = useState(false);
  const [img, setImg] = useState();
  const [imageURLS, setImageURLs] = useState();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    publish(data)
      .then((res) => {
        if (res?.status === 200) {
          toast(res?.data, {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
            className: "sucesstoaster",
          });

          setTimeout(() => {
            navigate(`/`);
          }, 3000);
        }
      })
      .catch((e) =>
        e?.response?.status === 400
          ? toast(e?.response?.data?.msg, {
              closeOnClick: false,
              closeButton: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
              position: toast.POSITION.TOP_RIGHT,
              draggable: true,
              rtl: false,
              pauseOnFocusLoss: false,
              className: "sucesstoaster",
            })
          : ""
      );
  };

  const profile_pic = localStorage.getItem("Dp");

  return (
    <div className="signupBox">
      <div className="SignInContainer">
        <div className="HindiLogo">
          <div className="logo">
            <h1 className="first" onClick={() => navigate("/")}>
              KAVISHALA <span className="second"> BOOKS</span>
            </h1>
          </div>
        </div>
        {/* <div className="SignIn">
          <h1 className="SignInHead">Publish</h1>
        </div> */}
        <form className="form" onSubmit={handleSubmit}>
          <TextField
            id="name"
            label="Your Name"
            type="text"
            variant="standard"
            className="password"
            name="name"
            value={data?.name}
            onChange={handleChange}
            required
          />
          <TextField
            id="email"
            label="Email"
            type="email"
            variant="standard"
            className="inputText"
            name="email"
            value={data?.email}
            onChange={handleChange}
            required
          />

          <TextField
            id="contact_number"
            label="Contact Number"
            type="number"
            variant="standard"
            className="password"
            name="phone"
            value={data?.contact_number}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            }}
            required
          />
          <TextField
            id="Kavishala Profile URL"
            label="Kavishala Profile URL"
            type="text"
            variant="standard"
            className="password"
            name="kavishala_profile_url"
            value={data?.college_name}
            onChange={handleChange}
            required
          />
          <TextField
            id="City"
            label="City"
            type="text"
            variant="standard"
            className="password"
            value={data?.city}
            onChange={handleChange}
            name="city"
            required
          />
          <TextField
            id="Course"
            label="Manuscript"
            rows={6}
            multiline
            type="text"
            variant="standard"
            className="password"
            value={data?.course_year}
            onChange={handleChange}
            name="manuscript"
            required
          />

          <div className="btnContainer">
            <Button variant="contained" className="LoginBtn" type="submit">
              Submit
              <ToastContainer />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PublishForm;
