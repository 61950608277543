import axios from "axios";

const baseRoute = "https://admin.kavishala.in";
// Home Page Apis

const allAuthor = () => {
  const urlPath = `/ebook/allauthor/`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const homePageCarousels = () => {
  const urlPath = `/ebook/carousel/`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getAuthorSlug = (slug) => {
  const urlPath = `/ebook/getauthor/${slug}/`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const allBooks = () => {
  const urlPath = `/ebook/allbooks/`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getBooksSlug = (slug) => {
  const urlPath = `/ebook/getbooks/${slug}/`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const allVideos = () => {
  const urlPath = `/ebook/allvideos/`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const publish = (data) => {
  const urlPath = `/ebook/kavishalaBookPublish/`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.post(url, data).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const staticPage = (slug) => {
  const urlPath = `/ebook/staticpage/${slug}/`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const loginAuthentication = (data) => {
  const urlPath = `/ebook/authorlogin/`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.post(url, data).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getBookHistory = () => {
  const urlPath = `/ebook/purchasehistory/?token=${localStorage.getItem(
    "book_token"
  )}`;
  const url = `${baseRoute}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  homePageCarousels,
  allAuthor,
  getAuthorSlug,
  allBooks,
  getBooksSlug,
  allVideos,
  staticPage,
  publish,
  loginAuthentication,
  getBookHistory,
};
