// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bookdetail_containerMain__B064D {
  margin: 11rem 5rem 0rem 8rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/BooksDetail/Bookdetail.module.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ","sourcesContent":[".containerMain{\n    margin: 11rem 5rem 0rem 8rem;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerMain": `Bookdetail_containerMain__B064D`
};
export default ___CSS_LOADER_EXPORT___;
