import React, { useEffect } from "react";
import "../../styles/styleModules/Footer/_footer.scss";

import { NavLink, useParams } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footermain">
      <div className="footercontainer">
        <div className="logo">
          <h1 className="first">
            KAVISHALA <span className="second"> BOOKS</span>
          </h1>
        </div>
        <div className="other_service">
          <div className="company_services">
            <h1 className="head">COMPANY</h1>
            <div className="border"></div>
            <div>
              <ul className="our_services">
                <li className="featuers">
                  <NavLink to="/about-us" style={{ color: "#fff" }}>
                    About
                  </NavLink>
                </li>
                <li className="featuers">
                  <NavLink to="/blog" style={{ color: "#fff" }}>
                    Blog
                  </NavLink>
                </li>
                <li className="featuers">
                  <NavLink to="/terms" style={{ color: "#fff" }}>
                    Terms
                  </NavLink>
                </li>
                <li
                  className="featuers"
                  onClick={() => window.open("https://kavishala.com/news")}
                >
                  Press
                </li>
              </ul>
            </div>
          </div>
          <div className="company_services">
            <h1 className="head">COMMUNITY</h1>
            <div className="border"></div>
            <ul className="our_services">
              <li className="featuers">
                <NavLink
                  to="https://www.instagram.com/kavishalabooks/"
                  style={{ color: "#fff" }}
                  target="_blank"
                >
                  Instagram
                </NavLink>
              </li>
              <li className="featuers">
                <NavLink
                  to="https://twitter.com/KavishalaBooks"
                  style={{ color: "#fff" }}
                  target="_blank"
                >
                  Twitter
                </NavLink>
              </li>
              <li className="featuers">
                <NavLink
                  to="https://www.facebook.com/KavishalaBooks/"
                  style={{ color: "#fff" }}
                  target="_blank"
                >
                  Facebook
                </NavLink>
              </li>
              <li
                className="featuers"
                onClick={() =>
                  window.open("https://kavishala.com/@kavishalabooks")
                }
              >
                <NavLink
                  to="https://kavishala.com/@kavishalabooks"
                  style={{ color: "#fff" }}
                  target="_blank"
                >
                  Kavishala
                </NavLink>
              </li>
              {/* <li className="featuers">Newsletter</li> */}
            </ul>
          </div>
          <div className="company_services">
            <h1 className="head">KAVISHALA BOOKS GROUP</h1>
            <div className="border"></div>
            <ul className="our_services">
              {/* <li className="featuers">About Us</li> */}
              <li
                className="featuers"
                onClick={() => window.open("https://store.kavishala.in")}
              >
                Ordering
              </li>
              <li className="featuers">
                <NavLink to="/influencer-program" style={{ color: "#fff" }}>
                  Influencer Program
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
