import "./styles/styleModules/style.css";
import "./Fonts/stylesheet.css";

import RouteSeperater from "./routes";
import SEO from "./SEO";

function App() {
  return (
    <>
      <SEO />
      <RouteSeperater />
    </>
  );
}

export default App;
