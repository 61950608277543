import React from "react";
import PublishForm from "../../components/Publish/PublishForm";
import SEO from "../../SEO";
const Published = () => {
  return (
    <div>
      <SEO
        title={`Kavishala Books | Publish Books`}
        og_url={`/Publish`}
        og_type="article"
        og_title={`Kavishala Books | Publish Books`}
        og_description=""
        og_image=""
      />
      <PublishForm />
    </div>
  );
};

export default Published;
