import React from "react";
import SignIn from "../../components/Sign/SignIn";
const Login = () => {
  return (
    <div>
      <SignIn />
    </div>
  );
};

export default Login;
