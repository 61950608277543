import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { allAuthor } from "../../api/api";
import { Helmet } from "react-helmet";
import temp from "../../assets/authBanner.png";
import SEO from "../../SEO";
const alpha = ["A-E", "F-K", "L-O", "P-S", "T-Z"];

export const Author = () => {
  const [authorList, setAuthorList] = useState([]);
  const [pageMetadata] = useState({
    pageTitle: "Kavishala Books | Authors",
    pageDescription:
      "Representing Some Of The Most Exciting Talent From Across Kavishala Book, And Writing From All Around The World, We Invite You To Meet Our Kavishala Books Authors!",
    pageImageUrl: "https://books.kavishala.com/images/post_og.png",
    pageUrl: "https://books.kavishala.com/Author",
  });
  useEffect(() => {
    const loadData = () => {
      allAuthor().then((res) => {
        setAuthorList(res?.data);
      });
    };
    loadData();
  }, []);

  return (
    <>
      <SEO
        title={pageMetadata?.pageTitle}
        og_url={pageMetadata?.pageUrl}
        og_type="article"
        og_title={pageMetadata?.pageTitle}
        og_description={pageMetadata?.pageDescription}
        og_image={pageMetadata?.pageImageUrl}
      />
      <section className="Main_HomeWrapper  Author_Wrapper">
        <div className="container">
          <div className="Author_content">
            <div className="Author_heading">
              <h2>Get to know our authors</h2>
              <h6>
                Representing some of the most exciting talent from across
                Kavishala Book, and writing from all around the world, we invite
                you to meet our Kavishala Books authors!
              </h6>
              <p>
                Get to know these powerful voices that are sure to become your
                new favorites.
              </p>
              <p className="filterText">
                Authors are listed alphabetically by last name.{" "}
              </p>
            </div>

            <div className="Author-Card_Wrap Category_Grid_Wrp">
              <div className="category_Grid_Content">
                {authorList &&
                  authorList?.map((ele, index) => (
                    <figure>
                      <Link to={`/Author/${ele?.slug}`} key={ele?.slug}>
                        <img
                          // onClick={() => handleRoute(ele)}
                          src={ele?.image}
                          alt="book"
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                        <h1>{ele?.name}</h1>
                        <button>Read Bio</button>
                      </Link>
                    </figure>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
