import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import "./Sign.scss";
import { loginAuthentication } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
// import Logo from "../../Utils/Images/favicon.png";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const SignIn = () => {
  const [data, setData] = useState();

  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    loginAuthentication(data)
      .then((res) => {
        const response = res;

        localStorage.setItem("author_slug", response?.data?.data?.author?.slug);
        localStorage.setItem("book_token", response?.data?.data?.token);

        const slug = localStorage.getItem("author_slug");
        if (response?.status === 200) {
          toast("Sign in Succesfull", {
            closeOnClick: false,
            closeButton: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 500,
            position: toast.POSITION.TOP_RIGHT,
            className: "sucesstoaster",
          });

          setTimeout(() => {
            navigate(`/Profile/${slug}`);
          }, 3000);
        }
      })

      .catch((e) =>
        e?.response?.status === 400
          ? toast(e?.response?.data?.msg, {
              closeOnClick: false,
              closeButton: false,
              type: toast.TYPE.ERROR,
              autoClose: 1000,
              position: toast.POSITION.TOP_RIGHT,
              draggable: true,
              rtl: false,
              pauseOnFocusLoss: false,
              className: "sucesstoaster",
            })
          : ""
      );
  };

  return (
    <>
      <div className="signBox">
        <div className="SignInContainer">
          <div className="logo">
            <h1 className="first" onClick={() => navigate("/")}>
              KAVISHALA <span className="second"> BOOKS</span>
            </h1>
          </div>

          <div className="SignIn">
            <h1 className="SignInHead">Log in</h1>
          </div>
          <form className="form" onSubmit={handleSubmit}>
            <TextField
              id="email"
              label="email"
              type="email"
              variant="standard"
              className="inputText"
              onChange={handleChange}
              name="email"
              required
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              variant="standard"
              className="password"
              name="password"
              onChange={handleChange}
              required
            />

            <div className="btnContainer">
              <Button variant="contained" className="LoginBtn" type="submit">
                Log in
                <ToastContainer />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default SignIn;
