import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { getAuthorSlug } from "../../../api/api";
import BookHistory from "../../BookDescription/BookHistory";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import SEO from "../../../SEO";
export const AuhorDescription = () => {
  let navigate = useNavigate();
  let { slug } = useParams();
  const [authorDetails, setAuthorDetails] = useState(() => {
    const storedAuthors = localStorage.getItem("authors");
    return storedAuthors ? JSON.parse(storedAuthors) : null;
  });
  const [bookDetailsReleted, setBookDetailsReleted] = useState([]);
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getAuthorSlug(slug);
        const { data } = response;
        setAuthorDetails(data);
        setBookDetailsReleted(data.author_books);
        // Set SEO metadata dynamically
        const pageTitle = `${data.name} - Author | My Website`;
        document.title = pageTitle;
      } catch (error) {
        console.error("Error fetching author data:", error);
      }
    };
    loadData();
  }, [slug]);

  return (
    <>
      <SEO
        title={`Kavishala Books | Author | ${authorDetails?.name}`}
        og_url={`Author/${authorDetails?.slug}`}
        og_type="article"
        og_title={`Kavishala Books | Author | ${authorDetails?.name}`}
        og_description={authorDetails?.about_author}
        og_image={authorDetails?.image}
      />

      <section className="Main_HomeWrapper Description_wrapper BookDesciption_Wrapper AuthorDescription_Wrapper">
        <div className="container" style={{ marginTop: "5%" }}>
          <div className="authorNameContainer">
            <h1 className="authorName">{authorDetails?.name}</h1>
          </div>
          <div className="Description_Content">
            <div className="Description_Left">
              <figure>
                <div className="img_Wrp">
                  <img src={authorDetails?.image} alt="Author profile" />
                </div>
              </figure>
            </div>
            <div className="Description_Right">
              <div className="About_Book">
                <div className="About-book-title">
                  <h2>{authorDetails?.author_full_name}</h2>
                </div>
                <figcaption>
                  <div className="bookDes_Wrp" id="About Author">
                    <div className="container">
                      <div className="bookDes_Wrp_Content">
                        <h2>About The Author</h2>
                        <p>{authorDetails?.about_author}</p>
                      </div>
                      <div className="textLink">
                        {authorDetails?.author_links?.map((value) => {
                          return (
                            <span
                              className="linkName"
                              onClick={() => window.open(value?.url)}
                            >
                              {value?.url_text}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>

          {/* Related-Books */}
          {/* <div className="AuthInfo">
            <AuthorInfo bookInfoReleted={bookDetailsReleted} />
          </div> */}

          <div className="Otherbook_Wrapper Author_desc_Wrp" id="Author Books">
            <div className="Otherbook-Heading">
              <h2>More books by this Author</h2>
            </div>
            <div className="Grid_Carousel_wrp">
              {bookDetailsReleted?.map((ele, index) => {
                return (
                  <div key={index} className="Grid-item">
                    <Link
                      to={`/BookList/${ele?.slug}`}
                      key={ele?.slug}
                      // onClick={() => goToBookDetailsPage(ele)}
                    >
                      <figure>
                        <img src={ele?.thumbnail_image} alt="book" />
                      </figure>
                      <figcaption>
                        <h3>{ele?.name}</h3>
                      </figcaption>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          {/* end-here--Other-Books */}
        </div>
      </section>
      <BookHistory />
    </>
  );
};
