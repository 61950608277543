// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeCrousel {
  margin-top: 5%;
}
.homeCrousel .bannerImage {
  width: 100%;
  height: 300px;
  cursor: pointer;
}

.VideoCrousel {
  margin: 5%;
}

iframe {
  border-radius: 10px;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  border-radius: unset !important;
  box-shadow: unset !important;
}

@media (max-width: 480px) {
  .homeCrousel {
    margin-top: 20%;
  }
  .homeCrousel .bannerImage {
    width: 100%;
    height: 150px;
  }
  .react-player-video {
    width: 100% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/homePage/homePage.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AAAI;EACI,WAAA;EACA,aAAA;EACA,eAAA;AAER;;AACA;EACI,UAAA;AAEJ;;AACA;EACI,mBAAA;AAEJ;;AACA;EACI,+BAAA;EACA,4BAAA;AAEJ;;AAAA;EACI;IACI,eAAA;EAGN;EAFM;IACI,WAAA;IACA,aAAA;EAIV;EADE;IACI,sBAAA;EAGN;AACF","sourcesContent":[".homeCrousel{\n    margin-top: 5%;\n    .bannerImage{\n        width: 100%;\n        height: 300px;\n        cursor: pointer;\n    }\n}\n.VideoCrousel{\n    margin: 5%;\n  \n}\niframe {\n    border-radius: 10px;\n}\n\n.css-bhp9pd-MuiPaper-root-MuiCard-root{\n    border-radius: unset !important;\n    box-shadow: unset !important;\n}\n@media(max-width:480px){\n    .homeCrousel{\n        margin-top: 20%;\n        .bannerImage{\n            width: 100%;\n            height: 150px;\n        }\n    }\n    .react-player-video{\n        width: 100% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
