import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import temp from "../../assets/test.jpeg";
import { useNavigate } from "react-router-dom";
const Publish = () => {
  const navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1220 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1220, min: 480 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section className="Main_HomeWrapper Description_wrapper BookDesciption_Wrapper AuthorDescription_Wrapper">
        <div className="container2">
          <div className="authorNameContainer">
            <h1 className="authorName">Get Publish With Us</h1>
          </div>
        </div>
      </section>
      <div className="allBookCaroselsContainer">
        <div className="homeCrousel" style={{ margin: "2.5% auto" }}>
          <div>
            <img
              src="https://kavishala.co.in/PUBLISH%20WITH%20KAVISHALA.jpg"
              className="bannerImage"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/publish")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Publish;
