import React, { useState } from "react";
import Classes from "./_appNavigation.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { menuNav } from "../../actions";
import Default from "../../assets/dp.png";
import Drawer from "@mui/material/Drawer";
import "./navbar.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
export default function NavigationSection() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("Profile");
  const [selectedOption, setSelectedOption] = useState(null);
  const logOut = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  const linkarr = [
    {
      name: "About",
      url: "/about-us",
    },
    {
      name: "Our Books",
      url: "/Booklist",
    },
    {
      name: "Our Authors",
      url: "/Author",
    },
    {
      name: "Publish with Kavishala",
      url: "/Publish",
    },
  ];

  const handleMenuList = (e) => {
    if (e === "/Booklist") {
      dispatch(menuNav(false));
    }
    navigate(e);
  };
  const [appDrawer, setAppDrawer] = useState(false);
  const handleOpen = () => {
    setAppDrawer(!appDrawer);
  };
  const slug = localStorage.getItem("author_slug");
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleChange = (value) => {
    if (value === "Log out") {
      logOut();
      setSelected(value);
      return;
    } else if (value === "Profile") {
      setSelected(value);
      navigate(`/Profile/${slug}`);

      return;
    } else if (value === "About Author") {
      setSelected(value);
      navigate(`/Profile/${slug}`);
      scrollToSection(value);
      setSelectedOption(null);

      return;
    } else if (value === "Author Books") {
      setSelected(value);
      navigate(`/Profile/${slug}`);
      scrollToSection(value);
      setSelectedOption(null);

      return;
    } else if (value === "Book History") {
      setSelected(value);
      navigate(`/Profile/${slug}`);
      scrollToSection(value);
      setSelectedOption(null);

      return;
    } else {
      return;
    }
  };
  const options = [
    "Profile",
    "About Author",
    "Author Books",
    "Book History",
    "Log out",
  ];
  return (
    <>
      <div
        className="mainContainer"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={`${Classes.navigationContainer} navigationContainer`}>
          <ul>
            <div className="HindiLogo">
              <div className="logo">
                <h1 className="first" onClick={() => navigate("/")}>
                  KAVISHALA <span className="second"> BOOKS</span>
                </h1>
              </div>
            </div>
            {linkarr.map((ele, index) => {
              return (
                <React.Fragment key={index}>
                  <li className={Classes.Navlinks}>
                    <div onClick={() => handleMenuList(ele.url)}>
                      {ele.name}
                    </div>
                  </li>
                  <li className={Classes.bullet}></li>
                </React.Fragment>
              );
            })}
            {localStorage?.getItem("book_token") ? (
              ""
            ) : (
              <FontAwesomeIcon
                onClick={handleOpen}
                className="menu"
                icon={faBars}
                style={{ cursor: "pointer" }}
              />
            )}
          </ul>
        </div>
        {localStorage?.getItem("book_token") ? (
          <div
            style={{
              // width: "12%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img src={Default} alt="" className="profileDp" />
            <Dropdown
              options={options}
              seleted={"Dashboard"}
              onChange={(e) => handleChange(e.value)}
              value={selected}
              className="drop-downs"
            />
          </div>
        ) : (
          <div className="Login_btn" onClick={() => navigate("/login")}>
            {/* <h6 className="Login">Login</h6> */}
          </div>
        )}
      </div>
      <Drawer
        anchor={"right"}
        open={appDrawer}
        onClose={() => setAppDrawer(false)}
        className="Drawer"
      >
        <div className="DrawerMainContainer">
          <div className="TopConatiner">
            <div className="TopConatiner_Left">
              <h1 className="AmbassadorsText">Kavishala Book</h1>
            </div>
            <div className="TopConatiner_right">
              <FontAwesomeIcon
                className="close"
                icon={faXmark}
                onClick={() => setAppDrawer(false)}
              />
            </div>
          </div>
          <div className="MideumContainer">
            {localStorage?.getItem("book_token") ? (
              ""
            ) : (
              <NavLink to="/about-us" style={{ textDecoration: "none" }}>
                <p className="routerBtn">About</p>
              </NavLink>
            )}
            <NavLink to="/Booklist" style={{ textDecoration: "none" }}>
              <p className="routerBtn">Our Books</p>
            </NavLink>
            <a href="/Author" style={{ textDecoration: "none" }}>
              <p className="routerBtn">Our Authors</p>
            </a>
            <a href="/Publish" style={{ textDecoration: "none" }}>
              <p className="routerBtn">Publish with Kavishala</p>
            </a>
            {localStorage?.getItem("book_token") ? (
              ""
            ) : (
              <a href="/Login" style={{ textDecoration: "none" }}>
                {/* <p className="routerBtn">Login</p> */}
              </a>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}
